body {
    overflow-x: hidden;
}

.about-page {
    padding: 20px 60px 10px;
}

.white-section {
    background-color: white;
}

h1, h2 {
    text-align: center;
}

p {
    text-align: justify;
    margin: 20px 0;
    padding-bottom: 20px;
}

.main-headline {
    color: #932826;
}

.orange-stripe-section {
    background-color: #fae8db;
    padding: 0;
    margin: 0px 0;
    border-radius: 5px;
    display: flex;
    align-items: stretch;
    height: auto;
}

.orange-stripe-section p {
    margin: 0;
}

.core-values-list {
    list-style-type: none;
    margin: 0 auto;
    padding: 0 20px 20px 20px;
    max-width: 100%;
}

.core-values-list li {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    gap: 5%;
    width: 100%;
}

.core-values-list .left,
.core-values-list .center,
.core-values-list .right {
    flex: none;
    width: 15%; 
}

.core-values-list .left {
    text-align: left;
}

.core-values-list .center {
    text-align: center;
}

.core-values-list .right {
    text-align: right;
}

.philosophy-content {
    display: flex;
    flex-grow: 1;
    padding-bottom: 20px;
}

.philosophy-text {
    flex: 1;
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.core-values-text {
    padding-left: 60px;
    padding-right: 60px;
}

.philosophy-image {
    flex: 0 0 33%;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin-right: 0;
}

.bushido-image {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    border-radius: 5px;
}

.grey-stripe-section {
    background-color: #ece8e6;
    padding: 20px 60px;
    border-radius: 5px;
}

.pen-pointing-image {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    display: block;
}

.image-section {
    text-align: center;
    overflow: hidden;
}

.founder-quote {
    font-style: italic;
    margin-bottom: 10px;
    padding: 0 200px 20px;
}

.quote-author {
    text-align: right;
    font-style: normal;
    margin-top: 0;
    padding-right: 20%;
}

.linkedin-link {
    color: #7a2320;
    text-decoration: underline;
    font-weight: normal;
}

.linkedin-link:hover {
    font-weight: bold;
}

@media (max-width: 768px) {
    html, body {
        max-width: 100%;
        overflow-x: hidden;
    }

    .about-page {
        padding: 20px 5%; /* Reduce left and right padding */
    }

    p {
        text-align: center;
        margin: 20px 0;
        padding-bottom: 20px;
    }
    
    .core-values-list {
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
    }

    .core-values-list li {
        gap: 5%;
    }

    .core-values-list .left,
    .core-values-list .center,
    .core-values-list .right {
        flex: none;
        width: 25%;
    }

    .philosophy-content {
        flex-direction: column;
        align-items: center;
        padding-bottom: 0px;
        margin-left: -5%;
        margin-right: -5%;
    }

    .philosophy-headline {
        padding-top: 20px;
    }

    .philosophy-text {
        padding-top: 10px; 
    }

    .core-values-text {
        padding-bottom: 0px;
        padding-left: 10%;
        padding-right: 10%;
    }

    .bushido-image {
        max-width: 80%;
        max-height: 200px;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .founder-quote {
        padding: 0 15px 20px;
    }

    .quote-author {
        padding-right: 20px;
    }
}