.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 1rem;
    position: relative;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-logo {
    margin: 0;
}

.navbar-button {
    display: none;
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    color: black;
}

.navbar-demo-button {
    background-color: #F68F1E;
    color: white;
    border: 2px solid #D76D1F;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 20px;
    margin-left: auto;
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
}

.navbar-demo-button:hover {
    background-color: #D61B26;
    border: 2px solid #932826;
    font-weight: bold;
}

.navbar-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.navbar-items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
}

.navbar-item {
    margin: 0 1rem;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Barlow', sans-serif;
}

.navbar-item a {
    color: rgba(45, 42, 38, 0.6);
    text-decoration: none;
    font-size: 1rem;
}

.navbar-item a:hover {
    text-decoration: underline;
    font-weight: bold;
}

.navbar-close {
    display: none;
}

@media (max-width: 768px) {
    .navbar {
        z-index: 1000;
    }

    .navbar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
    }
    
    .navbar-button {
        display: block;
        color: black;
        margin-left: auto;
    }

    .navbar-close {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 11;
        background-color: #D61B26;
        border-color: #D76D1F;
        color: #ffffff;
        border-radius: 8px;
    }

    .navbar-items, .navbar-menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background-color: white;
        transform: translateX(100%);
        transition: transform 0.3s ease-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 50px 10px;
    }

    .navbar-item {
        margin: 15px 0;
    }

    .navbar-items.open {
        transform: translateX(0);
    }

    .navbar-demo-button {
        order: -1;
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
        margin-left: 40%;
        
    }

    .navbar-demo-logo {
        max-width: 100px;
        height: auto;
    }
}

@media (min-width: 768px) {
    .navbar-items {
        display: flex;
        justify-content: flex-end;
    }

    .navbar-button, .navbar-close {
        display: none;
    }

    .navbar-demo-button {
        display: inline-block;
    }
}
