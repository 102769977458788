.white-contact-section {
    background-color: white;
}

.white-contact-section-paragraph {
    padding-left: 150px;
    padding-right: 150px;
    text-align: center;
}

.contact-options-section {
    background-color: #ece8e6;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0px auto;
    padding: 20px 60px;
}

.appointment-section,
.email-section {
    flex: 1;
    padding: 20px;
}

.appointment-section h1,
.email-section h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 2rem;
    line-height: 1.5;
}

.email-section {
    text-align: center;
}

.email-section a {
    color: #7a2320;
    text-decoration: none;
    font-weight: normal;
    font-size: 1.2rem;
}

.email-section a:hover {
    font-weight: bold;
    text-decoration: underline;
}

.hubspot-contactus-form-section {
    margin-top: 0px;
    padding-top: 0px;
}

.contact-form-header {
    margin-top: 0px;
    padding-top: 40px;
}

@media (max-width: 768px) {
    .white-section-paragraph {
        text-align: center;
    }

    .contact-options-section {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px 0px 0px 0px;
    }

    .appointment-section,
    .email-section {
        width: 100%;
        padding: 20px 0;
    }

    .appointment-section h1,
    .email-section h1 {
        font-size: 1.8rem;
    }

    .white-contact-section-paragraph {
        padding-left: 20px;
        padding-right: 20px;
    }

    .contact-form-header {
        padding-bottom: 20px;
        font-size: 1.8rem;
    }
}