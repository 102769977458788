.home-page {
    position: relative;
    text-align: center;
    color: white;
}

.video-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    z-index: -1;
}

.background-video {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.image-block {
    width: 30%;
    margin: 0 1%;
    text-align: center;
}

.image-strip {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    background-color: white;
    padding: 60px 5% 40px;
}

.strip-image {
    width: auto;
    height: 250px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.strip-image:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.strip-image:not(:last-child) {
    margin-right: 2%;
}

/* Styles for the first white stripe block */
.image-title {
    color: #932826;
    font-size: 1.2rem;
    margin-bottom: 5px;
    text-decoration: none;
}

.image-title:hover {
    text-decoration: underline;
    transform: scale(1.05);
}

.image-description {
    color: black;
    font-size: 1rem;
    text-align: center;
}

/* Styles for the orange stripe block */
.beige-stripe-block {
    display: flex;
    align-items: center;
    background-color: #fae8db; 
    padding: 40px 10%;
    box-sizing: border-box;
    width: 100%;
}

.beige-stripe-block:hover .homepage-stripe-text a {
    text-decoration: underline;
    font-weight: bold;
}

.homepage-stripe-image {
    width: 400px;
    height: auto;
    margin-right: 60px;
    cursor: pointer;
    transition: transform 0.3s ease;
    border-radius: 8px;
}

.homepage-stripe-image:hover {
    transform: scale(1.1);
}

.homepage-stripe-text {
    flex-grow: 1;
    flex-basis: 50%;
    text-align: left;
}

.homepage-stripe-text h3 {
    color: black;
    font-size: 24px;
}

.homepage-stripe-text p {
    color: black;
    font-size: 16px;
}

.homepage-stripe-text a {
    font-weight: bold;
    color: #932826;
    text-decoration: none;
}

.homepage-stripe-text a:hover {
    text-decoration: underline;
}

/* Styles for the 2nd white stripe block */
.white-stripe-block {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    padding: 40px 10%;
    box-sizing: border-box;
    width: 100%;
    flex-direction: row-reverse;
}

.homepage-stripe-image-left {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-left: 40px;
    border-radius: 8px;
    transition: transform 0.3s ease;
    cursor: pointer;
    order: 2;
}

.homepage-stripe-image-left:hover {
    transform: scale(1.1);
}

.homepage-stripe-text-right {
    flex-grow: 1;
    flex-basis: 50%;
    text-align: left;
    color: black; 
    padding-right: 20px;
}

.homepage-white-stripe-block a {
    display: block;
}

.homepage-stripe-text-right h3 {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 0 10px 0;
    font-size: 24px;
    color: black;
}

.homepage-stripe-text-right p {
    margin: 0 0 5px 0;
    font-size: 16px;
}

.homepage-demo-button {
    background-color: #F68F1E;
    color: white;
    border: 2px solid #D76D1F;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 30px;
    margin-left: auto;
    font-weight: bold;
    font-family: 'Barlow', sans-serif;
}

.homepage-demo-button:hover {
    background-color: #D61B26;
    border: 2px solid #932826;
    font-weight: bold;
}

/* Styles for the magnifying glass image strip */
.full-width-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.global-solutions-container {
    position: relative;
    text-align: center;
    color: white;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
}

.overlay-text h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;
}

.overlay-text p {
    text-align: center;
    font-size: 1.5rem;
}

/* Styling for HubSpot form */
.hubspot-form-container {
    padding: 20px 200px;
    background-color: #fff;
    max-width: 960px;
}

.form-heading {
    text-align: center;
    color: black;
    margin-bottom: 50px;
    font-size: 2rem;
}

.form-paragraph {
    text-align: center;
}

.map-container {
    margin-bottom: 0px;
}

/* Styling for header banner on About, Services and Contact Us Pages */
.kanji-banner {
    position: relative;
    width: 100%;
}

.kanji-banner-image {
    width: 100%;
    height: auto;
    display: block;
}

.kanji-banner-text {
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translateY(-50%);
    font-size: 4rem;
    color: white;
    text-align: center;
    width: 50%;
    padding: 0 20px;
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
}

@media (max-width: 768px) {
    .image-strip {
        flex-direction: column;
        padding: 10px 5%;
    }

    .image-block {
        width: 100%;
        margin-bottom: 20px;
    }

    .image-block:first-child {
        margin-top: 40px;
    }

    .strip-image {
        width: auto;
        height: 250px;
        margin-bottom: 10px;
        margin-right: 0;
    }

    .image-description {
        padding-left: -5%;
        padding-right: -5%;
    }

    .beige-stripe-block, .white-stripe-block {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .homepage-stripe-image {
        margin: 20px auto 20px;
        width: 100%;
        max-width: 400px;
        border-radius: 8px;
    }

    .homepage-stripe-text {
        text-align: center;
    }
    
    .homepage-stripe-image-left {
        width: 100%;
        height: 150px;
        object-fit: cover;
        object-position: center;
        margin-top: 20px;
        margin-left: 0%;
        margin-bottom: 25px;
        order: 1;
    }

    .homepage-stripe-text-right {
        text-align: center;
        padding-left: 5%; /* Ensure consistent padding on mobile */
        padding-right: 5%; /* Ensure consistent padding on mobile */
        margin-left: auto; /* Center the section */
        margin-right: auto;
    }

    .full-width-image {
        height: 150px;
        object-fit: cover;
    }
    
    .overlay-text h2 {
        font-size: 1.4rem;
        white-space: nowrap;
        text-align: center;
    }

    .overlay-text p {
        font-size: 1.1rem;
    }

    .hubspot-form-container {
        padding-top: 10px;
        padding-left: 60px;
        padding-right: 60px;
    }
    
    .kanji-banner-text {
        font-size: 2rem;
        right: -20%;
        width: 90%;
        padding: 0 10px;
    }
}