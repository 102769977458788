/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100..900;1,100..900&family=Inter:ital,wght@0,100..900;1,100..900&display=swap');

/* Reset some default styles */
body {
  background-color: #f0f0f0;
  margin: 0;
  font-family: 'Inter', sans-serif; /* Set Inter as the default font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-optical-sizing: auto;
}

code {
  font-family: 'Inter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; /* Inter added for consistency */
}

/* Specific font weights for different use-cases */
.inter-extra-light {
  font-weight: 200; /* ExtraLight for body text */
}

.inter-regular {
  font-weight: 400; /* Regular for alternate small headers */
}

.inter-medium {
  font-weight: 500; /* Medium for small headers */
}

.inter-semibold {
  font-weight: 600; /* SemiBold for headers */
}

/* Apply Inter across different elements for consistency */
h4, h5, h6 {
    font-family: 'Inter', sans-serif; /* Ensure all headers use Inter */
}

/* Apply Barlow to all h3 elements */
h1, h2, h3 {
    font-family: 'Barlow', sans-serif; /* Use Barlow for all h3 headers */
    font-weight: bold;
}
