.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #b30000 0%, #ff4d4d 100%);
    box-shadow: 0 -1px 5px rgba(0,0,0,0.2);
    width: 100%;
    padding: 10px 0;
    position: static;
    z-index: 1000;
}

.footer-content {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    padding: 0 40px;
    margin: 0 auto;
}

.footer-logo {
    height: 200px;
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    font-family: 'Barlow', sans-serif;
}

.column-left {
    align-items: flex-start;
}

.column a, .column p {
    color: white;
    text-decoration: none;
    margin: 5px 0;
}

.column a:hover {
    text-decoration: underline;
    font-weight: bold;
}

.column h3 {
    color: white;
    font-weight: bold;
    text-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.demo-button {
    background-color: white;
    color: #b30000;
    border: 2px solid #d4d4d3;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 20px;
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
    font-size: 1rem;
}

.demo-button:hover {
    background-color: #d4d4d3;
    border: 2px solid white;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .footer-logo {
        width: 80%;
        height: auto;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 20px;
        align-self: center;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }

    .column {
        align-items: center;
        text-align: center;
        margin-bottom: 15px;
        width: 100%;
    }

    .column h3 {
        font-size: 1.5rem;
    }

    .column p, .column a {
        font-size: 1.2rem;
    }

    .demo-button {
        width: 100%;
        font-size: 1.2rem;
    }
}