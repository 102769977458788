.white-stripe {
    background-color: white;
    padding: 20px 60px 10px;
    text-align: justify;
}

.white-stripe h1 {
    font-size: 2.5rem;
    color: #932826;
    text-align: center;
}

.white-stripe p {
    margin: 20px 0;
    padding-bottom: 20px;
    font-size: 1.2rem;
    text-align: justify;
    font-family: 'Barlow', sans-serif;
}

.white-header {
    padding-left: 60px;
    padding-right: 60px;
}

.center {
    text-align: center;
}

.header {
    text-align: left;
}

.paragraph {
    margin: 10px 0;
    padding-bottom: 20px;
    font-size: 1rem;
    color: black;
    font-family: 'Inter', sans-serif;
}

/* Orange stripe section below white header banner */
.orange-stripe {
    display: flex;
    align-items: center;
    background-color: #fae8db;
    padding: 40px 10%;
    box-sizing: border-box;
    width: 100%;
    color: black;
}

.stripe-image {
    width: 300px;
    height: auto;
    margin-right: 40px;
    border-radius: 8px;
    transition: none;
}

.stripe-image:hover {
    transform: none;
    cursor: default;
}

.stripe-text {
    flex-grow: 1;
    font-size: 1.2rem;
}

.stripe-text h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #932826;
}

/* 2nd white stripe section */
.white-stripe-right {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 40px 10%;
    box-sizing: border-box;
    width: 100%;
    color: black;
}

.stripe-image-right {
    width: 300px;
    height: auto;
    margin-left: 40px;
    border-radius: 8px;
    transition: none;
}

.stripe-image-right:hover {
    transform: none;
    cursor: default;
}

/* Light gray section */
.gray-stripe {
    display: flex;
    align-items: center;
    background-color: #ece8e6;
    padding: 40px 10%;
    box-sizing: border-box;
    width: 100%;
    color: black;
}

.stripe-image-left {
    width: 300px;
    height: auto;
    margin-right: 40px;
    border-radius: 8px;
    transition: none;
}

.stripe-image-left:hover {
    transform: none;
    cursor: default;
}

@media (max-width: 768px) {
    .white-header {
        padding-left: 5%;
        padding-right: 5%;
    }
    
    .orange-stripe,
    .white-stripe-right,
    .gray-stripe {
        flex-direction: column;
        text-align: center;
        padding-bottom: 10px;
    }

    .stripe-image,
    .stripe-image-right,
    .stripe-image-left {
        width: auto;
        height: 250px;
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 100%;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .header {
        text-align: center;
        padding-bottom: 20px;
    }

    .stripe-text {
        text-align: center;
        order: 2;
    }
}